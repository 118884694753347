import React from "react";
import styled from "styled-components";
import Promo1Img from "../assets/promo-1.png";
import Promo2Img from "../assets/promo-2.png";
import Promo3Img from "../assets/promo-3.png";

const Container = styled.div`
  background-color: #f2f2f2;
  text-align: center;
  padding: 50px 20px;

  @media screen and (min-width: 384px) {
    padding: 50px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 100px;
  }
`;

const Headline = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 40px auto;
`;

const GridContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @media screen and (min-width: 384px) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px;
    margin-right: 0px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 50px;
    margin-right: 50px;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    margin-left: 50px;
    margin-right: 50px;
  }
`;

const GridItem = styled.div`
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  color: #000;
  padding: 30px 0px;
  font-size: 20px;

  h4 {
    color: #890e57;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 21px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  img {
    display: block;
    margin: 0 auto 15px;
  }

  @media screen and (min-width: 384px) {
    padding: 30px;
  }
`;

const ReadMore = styled.a`
  background-color: #b71375;
  color: #fff;
  padding: 10px 40px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;

  @media screen and (min-width: 768px) {
    padding: 12px 10px;
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 12px 15px;
    font-size: 20px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 12px 40px;
    font-size: 22px;
    line-height: 48px;
  }
`;

const PromoAction: React.FC = () => {
  return (
    <Container>
      <Headline>Zasady akcji promocyjnej</Headline>
      <GridContainer>
        <GridItem>
          <img
            src={Promo1Img}
            alt=""
            style={{ maxWidth: "224px", width: "100%" }}
          />
          Kup promocyjny<br />Pakiet produktów MEDISEPT
        </GridItem>
        <GridItem>
          <img
            src={Promo2Img}
            alt=""
            style={{ maxWidth: "224px", width: "100%" }}
          />
          Zarejestruj kod
        </GridItem>
        <GridItem>
          <img
            src={Promo3Img}
            alt=""
            style={{ maxWidth: "224px", width: "100%" }}
          />
          Odbierz Nagrodę!
        </GridItem>
      </GridContainer>
      <div style={{ color: "#000", fontSize: "120%" }}>
        <strong>Dowiedz się, gdzie możesz zamówić pakiet</strong>
      </div>
      <br />
      <ReadMore href="https://medisept.pl/kontakt/" target="_blank">
        Skontaktuj się z nami
      </ReadMore>
    </Container>
  );
};

export default PromoAction;
