import styled from "styled-components";
import Header from "./components/Header";
import RegistrationForm from "./components/form/RegistrationForm";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Products from "./components/products/Products";
import "./App.css";
import A1Img from "./assets/a-1.png";
import A2Img from "./assets/a-2.png";
import A3Img from "./assets/a-3.png";
import A4Img from "./assets/a-4.png";
import InstagramPImg from "./assets/instagram-p.png";
import InstagramImg from "./assets/instagram.png";
import FacebookPImg from "./assets/facebook-p.png";
import FacebookImg from "./assets/facebook.png";
import LinkedInImg from "./assets/linkedin.png";
import Section1 from "./components/Section1";
import PakietImg from "./assets/pakiet.png";
import CertyfikatImg from "./assets/certyfikat.png";
import PromoAction from "./components/PromoAction";

const PageContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Source Sans 3", sans-serif;
  color: #4a4a49;
  background-color: #fff;

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
`;

const Section = styled.div`
  padding: 50px 0;
`;

const HeaderSection = styled(Section)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const About = styled(Section)`
  background-color: #f2f2f2;
  text-align: center;
  padding: 50px 20px;

  @media screen and (min-width: 384px) {
    padding: 50px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 100px;
  }
`;

const Package = styled(Section)`
  background-color: #fff;
  text-align: center;
  padding: 50px 20px;

  @media screen and (min-width: 384px) {
    padding: 50px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 100px;
  }
`;

const Footer = styled(Section)`
  background-color: #890e57;
  color: #c4c4c4;
  text-align: center;
  font-size: 12px;
  padding: 40px 50px;
`;

const Headline = styled.h1`
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 40px auto;
`;

export const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftSection = styled.div`
  width: 100%;
  text-align: left;

  @media screen and (min-width: 768px) {
    width: 50%;
    font-size: 80%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    font-size: 100%;
  }
`;

export const RightSection = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    font-size: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 80%;
  }
`;

export const Link = styled.a`
  background-color: #890e57;
  color: white;
  padding: 4px 40px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;

  @media screen and (min-width: 768px) {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 6px 15px;
    font-size: 16px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 6px 40px;
    font-size: 18px;
    line-height: 48px;
  }
`;

function App() {
  return (
    <PageContainer>
      <HeaderSection>
        <Header />
      </HeaderSection>
      <Package>
        <Headline>Poznaj zawartość pakietu promocyjnego</Headline>
        <Container>
          <LeftSection>
            <p style={{ marginBottom: "40px" }}>
              W skład Pakietu wchodzą produkty niezbędne w salonie kosmetycznym
              w celu zapewnienia prawdziwej dezynfekcji podczas codziennej pracy
            </p>
            <ul style={{ marginBottom: "40px", listStyleType: "disc" }}>
              <strong>W skład Pakietu wchodzą:</strong>
              <li style={{ marginLeft: "20px" }}>Velox Spray 1L</li>
              <li style={{ marginLeft: "20px" }}>
                Velox Wipes NA – puszka z wkładem
              </li>
              <li style={{ marginLeft: "20px" }}>Alodes N – 1L</li>
              <li style={{ marginLeft: "20px" }}>Velodes Skin – 500ml</li>
              <li style={{ marginLeft: "20px" }}>Velodes Soap – 500ml</li>
              <li style={{ marginLeft: "20px" }}>
                Velodes Cream – tubka 100ml
              </li>
            </ul>
            <ul style={{ marginBottom: "40px", listStyleType: "disc" }}>
              <strong>
                Do każdego Pakietu dołączamy materiały wspierające:
              </strong>
              <li style={{ marginLeft: "20px" }}>Technika mycia rąk</li>
              <li style={{ marginLeft: "20px" }}>Technika dezynfekcji rąk</li>
            </ul>
          </LeftSection>
          <RightSection>
            <StyledImg src={PakietImg} alt="" />
          </RightSection>
        </Container>
      </Package>
      <PromoAction />
      <Package style={{ paddingBottom: "20px", paddingTop: "0px" }}>
        <Headline>Certyfikat Bezpieczny SALON</Headline>
        <Container>
          <LeftSection>
            <StyledImg src={CertyfikatImg} alt="" />
          </LeftSection>
          <RightSection
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "40px",
            }}
          >
            <h3 style={{ textAlign: "left" }}>
              Pokaż Klientom, że dbasz o ich bezpieczeństwo
            </h3>
            <p style={{ textAlign: "left", marginTop: "30px" }}>
              Zarejestruj Pakiet i zdobądź Certyfikat “Bezpieczny salon”! W
              Nagrodę za rejestrację Pakietu wysyłamy zestaw produktów MEDISEPT,
              certyfikat i plan higieny w salonie kosmetycznym. Wyeksponuj te
              materiały w widocznym miejscu, zrób zdjęcie i podziel się nim w
              serwisach społecznościowych z hashtagiem #bezpiecznysalon. Pokaż
              klientom, że w Twoim salonie będą bezpieczni!
            </p>
            <p style={{ textAlign: "left", marginTop: "30px" }}>
              <a
                href="https://www.instagram.com/mediseptpl/"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={InstagramPImg} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/mediseptpl/?locale=pl_PL"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={FacebookPImg} alt="Facebook" />
              </a>
            </p>
          </RightSection>
        </Container>
      </Package>
      <Section1 />
      <About>
        <Headline>
          Poznaj zagrożenia, na które można stosować produkty Medisept
        </Headline>
        <div className="grid-container">
          <div className="grid-card">
            <img src={A1Img} alt="" style={{ marginBottom: "20px" }} />
            <p>
              Naruszenie ciągłości tkanek i kontakt z krwią podczas zabiegów
              pielęgnacji ciała, skóry, twarzy, paznokci
            </p>
          </div>
          <div className="grid-card">
            <img src={A2Img} alt="" style={{ marginBottom: "20px" }} />
            <p>
              Kontakt zakażonej krwi z uszkodzoną skórą, błoną śluzową zdrowej
              osoby
            </p>
          </div>
          <div className="grid-card">
            <img src={A3Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Ryzyko wniknięcia do organizmu czynników chorobotwórczych</p>
          </div>
          <div className="grid-card">
            <img src={A4Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Zagrożenie powstania poważnych powikłań i chorób</p>
          </div>
        </div>
      </About>
      <Products />
      <RegistrationForm />
      <Footer>
        <div className="grid-container" style={{ marginBottom: "40px" }}>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <strong>Kontakt</strong>
              <br />
              Medisept Sp. z o.o.
              <br />
              ul. Ludwika Spiessa 4<br />
              20-270 Lublin
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <br />
              e-mail: info@medisept.pl
              <br />
              tel. +48 81 535 22 22
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <br />
              NIP: 9460010016
              <br />
              REGON: 430566102
              <br />
              BDO: 000001634
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <strong>Odwiedź nas na:</strong>
              <br />
              <a
                href="https://www.instagram.com/mediseptpl/"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={InstagramImg} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/mediseptpl/?locale=pl_PL"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={FacebookImg} alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/medi-sept/?originalSubdomain=pl"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={LinkedInImg} alt="LinkedIn" />
              </a>
            </p>
          </div>
        </div>
        <p>MEDISEPT 2023. All rights reserved</p>
      </Footer>
      <ScrollToTopButton />
    </PageContainer>
  );
}

export default App;
